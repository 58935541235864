import * as THREE from 'three'

export default class MetaBallService {
  /**
   * Based on Metaball script by SATO Hiroyuki
   * http://shspage.com/aijs/en/#metaball
   */
  run(radius1, radius2, center1, center2, distanceFactor, handleSize = 2.4, v = 0.5) {
    const HALF_PI = Math.PI / 2
    const d = this.dist(center1, center2)
    const maxDist = (radius1 + radius2) * distanceFactor
    let u1, u2

    if (radius1 === 0 || radius2 === 0 || d > maxDist || d <= Math.abs(radius1 - radius2)) {
      return null
    }

    if (d < radius1 + radius2) {
      u1 = Math.acos((radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d))
      u2 = Math.acos((radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d))
    } else {
      u1 = 0
      u2 = 0
    }

    // All the angles
    const angleBetweenCenters = this.angle(center2, center1)
    const maxSpread = Math.acos((radius1 - radius2) / d)

    const angle1 = angleBetweenCenters + u1 + (maxSpread - u1) * v
    const angle2 = angleBetweenCenters - u1 - (maxSpread - u1) * v
    const angle3 = angleBetweenCenters + Math.PI - u2 - (Math.PI - u2 - maxSpread) * v
    const angle4 = angleBetweenCenters - Math.PI + u2 + (Math.PI - u2 - maxSpread) * v

    // Points
    const p1 = this.getVector(center1, angle1, radius1)
    const p2 = this.getVector(center1, angle2, radius1)
    const p3 = this.getVector(center2, angle3, radius2)
    const p4 = this.getVector(center2, angle4, radius2)

    // Define handle length by the distance between both ends of the curve
    const totalRadius = radius1 + radius2
    const d2Base = Math.min(v * handleSize, this.dist(p1, p3) / totalRadius)

    // Take into account when circles are overlapping
    const d2 = d2Base * Math.min(1, (d * 2) / (radius1 + radius2))

    const r1 = radius1 * d2
    const r2 = radius2 * d2

    const h1 = this.getVector(p1, angle1 - HALF_PI, r1)
    const h2 = this.getVector(p2, angle2 + HALF_PI, r1)
    const h3 = this.getVector(p3, angle3 + HALF_PI, r2)
    const h4 = this.getVector(p4, angle4 - HALF_PI, r2)

    return this.createPath(p1, p2, p3, p4, h1, h2, h3, h4, radius2, center2)
  }

  createPath(p1, p2, p3, p4, h1, h2, h3, h4, r, center2) {
    const path = new THREE.Path()

    path.moveTo(...p1)
    path.bezierCurveTo(h1[0], h1[1], h3[0], h3[1], p3[0], p3[1])
    path.absarc(center2[0], center2[1], r - 1, this.angle(p3, center2), this.angle(p4, center2))
    path.bezierCurveTo(h4[0], h4[1], h2[0], h2[1], p2[0], p2[1])
    path.closePath()

    return path;
  }

  moveTo([x, y] = [0, 0], element) {
    element.setAttribute('cx', x)
    element.setAttribute('cy', y)
  }

  line([x1, y1] = [0, 0], [x2, y2] = [0, 0], element) {
    element.setAttribute('x1', x1)
    element.setAttribute('y1', y1)
    element.setAttribute('x2', x2)
    element.setAttribute('y2', y2)
  }

  dist([x1, y1], [x2, y2]) {
    return ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** 0.5
  }

  angle([x1, y1], [x2, y2]) {
    return Math.atan2(y1 - y2, x1 - x2)
  }

  getVector([cx, cy], a, r) {
    return [cx + r * Math.cos(a), cy + r * Math.sin(a)]
  }
}
