//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputStep from '@/components/contact/InputStep.vue'
import MethodStep from '@/components/contact/MethodStep.vue'
import TwoInputStep from '@/components/contact/TwoInputStep.vue'
import PdfStep from '@/components/contact/PdfStep.vue'
import VoiceRecordStep from '@/components/contact/VoiceRecordStep.vue'
import TextFormStep from '@/components/contact/TextFormStep.vue'
import MoreDetailsStep from '@/components/contact/MoreDetailsStep.vue'
import SuccessStep from './SuccessStep.vue'
import ReviewRecordStep from './ReviewRecordStep.vue'

import gsap from 'gsap'

export default {
  inject: ['isMobile', 'metaballContainer'],
  props: {
    hiringModel: { type: Object, required: true },
  },
  data() {
    return {
      form_steps: {
        default: [
          { id: 'email', is: InputStep, nextStep: 'method' },
          { id: 'method', is: MethodStep },
          { id: 'voice-record', is: VoiceRecordStep },
          { id: 'text-form', is: TextFormStep },
          { id: 'review-record', is: ReviewRecordStep },
          { id: 'success', is: SuccessStep },
        ],
        vaga: [
          { id: 'name', is: InputStep, template: 'name', step: 1, nextStep: 'contact' },
          {
            id: 'contact',
            is: TwoInputStep,
            template: 'contact',
            step: 2,
            backStep: 'name',
            nextStep: 'curriculo',
          },
          { id: 'curriculo', is: PdfStep, step: 3, backStep: 'contact', nextStep: 'links' },
          {
            id: 'links',
            is: TwoInputStep,
            template: 'links',
            step: 4,
            backStep: 'curriculo',
            nextStep: 'more-details-form',
          },
          {
            id: 'more-details-form',
            is: MoreDetailsStep,
            step: 5,
            backStep: 'links',
          },
          { id: 'success', is: SuccessStep },
        ],
      },
      isMobile: false,
      actualStep: {
        default: 'email',
        vaga: 'name',
      }[this.hiringModel.form],
      labelPosition: 'on-border',
      show_step: false,
      form: {
        text: '',
        email: '',
      },
    }
  },
  mounted() {
    this.show()
    window.addEventListener('resize', this.resizeEvent)
    this.resizeEvent()
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEvent)
  },
  computed: {
    selectedTemplate() {
      return this.form_steps[this.hiringModel.form].find(d => d.id == this.actualStep).template
    },
    selectedNextStep() {
      return this.form_steps[this.hiringModel.form].find(d => d.id == this.actualStep).nextStep
    },
    selectedBackStep() {
      return this.form_steps[this.hiringModel.form].find(d => d.id == this.actualStep).backStep
    },
    selectedForm() {
      return this.form_steps[this.hiringModel.form].find(d => d.id == this.actualStep).is
    },
    selectedStep() {
      return this.form_steps[this.hiringModel.form].find(d => d.id == this.actualStep).step
    },
  },
  methods: {
    resizeEvent() {
      this.isMobile = document.documentElement.clientWidth <= 1320
    },
    show() {
      this.$nextTick(() => {
        this.show_step = true
      })
    },
    hide() {
      this.show_step = false
    },
    fadeOutMetaballs() {
      gsap.fromTo(
        this.metaballContainer.value.$el.querySelectorAll('[metaball]'),
        { opacity: 0 },
        { opacity: 1, duration: 0.25, stagger: 0.075 },
        0
      )
    },
    goToStep(step) {
      this.show_step = false
      this.fadeOutMetaballs()
      setTimeout(() => {
        this.actualStep = step
        this.show()
      }, 250)
    },
  },
}
