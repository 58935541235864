//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'

//Textarea Step form
export default {
  props: {
    hiringModel: { type: Object, required: true },
    form: { type: Object, required: true },
  },
  data: () => ({
    metaballsActive: false,
    isMobile: { type: Boolean, required: true },
  }),
  mounted() {
    this.$refs.text.focus()
    this.$ga.event({
      eventCategory: 'Contact form',
      eventAction: 'reached',
      eventLabel: 'text-form',
    })
  },
  methods: {
    goBack() {
      this.$ga.event({
        eventCategory: 'Contact form',
        eventAction: 'return from',
        eventLabel: 'text-form',
      })
      this.$emit('goToStep', 'method')
    },
    encode(data) {
      return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    success() {
      this.$emit('goToStep', 'success')
    },
    fail() {
      alert(this.$t('contato.falha_ao_enviar_formulário_tente_novamente_mais_tarde'))
    },
    sendForm() {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
      let path = this.hiringModel.form == 'vaga' ? '/send-candidato' : '/send-contato'
      let formName = this.hiringModel.form == 'vaga' ? 'send-candidato' : 'contact'
      const form = {
        email: this.form.email,
        name: this.form.email,
        message: this.form.text,
        subject:
          '[Site Outra Coisa] ' +
          (this.hiringModel.form == 'vaga' ? 'Novo Candidato' : 'Novo Contato'),
      }
      if (this.hiringModel.form == 'vaga') {
        form.vaga = this.hiringModel.vaga
      }
      axios
        .post(
          path,
          this.encode({
            'form-name': formName,
            ...form,
          }),
          axiosConfig
        )
        .then(this.success.bind(this))
        .catch(this.fail.bind(this))
    },
    submit() {
      this.sendForm()
    },
    createMetaballs() {
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        if (this.form.text.length >= 0 && !this.metaballsActive) {
          this.metaballsActive = true
          this.createMetaballs()
        } else if (this.form.text.length == 0 && this.metaballsActive) {
          this.metaballsActive = false
          this.createMetaballs()
        }
      },
    },
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  computed: {
    validText() {
      return this.form.text.length !== 0
    },
  },
}
