export default {
  app: {
    title: 'Other Digital',
    logo: '/logo-other-digital.svg',
  },
  message: {
    'conheca-mais': 'Learn more about the project ',
  },
  meta: require('./meta.json'),
  home: require('./home.json'),
  trabalhos: require('./trabalhos.json'),
  contato: require('./contato.json'),
  misc: require('./misc.json'),
  metodologia: require('./metodologia.json'),
}
