//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    hiringModel: { type: Object, required: true },
  },
  methods: {
    goToProjects() {
      console.log(this.$route)
      if (this.$route.fullPath != '/trabalhos') {
        this.$router.push({ path: '/trabalhos' })
      } else {
        this.$nuxt.$emit('hide-contact-modal')
      }
    },
    createMetaballs() {
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
  },
  mounted() {
    this.createMetaballs()
    this.$ga.event({
      eventCategory: this.hiringModel.form == 'vaga' ? 'Careers form' : 'Contact form',
      eventAction: 'sent',
      eventLabel: 'success',
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  computed: {
    validText() {
      return this.form.text.length !== 0
    },
  },
}
