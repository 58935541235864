//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
  },
  mounted() {
    this.$nextTick(() =>
      $nuxt.$emit('create-metaballs', { tintColor: '#000000', isleColor: '#000000' })
    )
    this.$ga.event({
      eventCategory: 'Contact form',
      eventAction: 'reached',
      eventLabel: 'methods',
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  computed: {},
}
