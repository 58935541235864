import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales/'
Vue.use(VueI18n)

const origin = window.location.origin;
const isEn = origin.indexOf('otherdigital') != -1

const i18n = new VueI18n({
  messages: locales,
  fallbackLocale: 'pt-BR',
  locale: isEn ? 'en' : 'pt-BR', //pt-BR, fr, es
})

export default ({ app }) => {
  app.i18n = i18n
}
