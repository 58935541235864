//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadIcon from '@/components/contact/UploadIcon'
import CheckIcon from '@/components/contact/CheckIcon'
import axios from 'axios'

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    template: { type: String, default: () => 'contact' },
    hiringModel: { type: Object, required: true },
    step: Number,
    nextStep: String,
    backStep: String,
  },
  components: {
    UploadIcon,
    CheckIcon,
  },
  data: () => ({
    selectedFile: null,
  }),
  mounted() {
    this.createMetaballs()
    this.$ga.event({
      eventCategory: 'Careers form',
      eventAction: 'reached',
      eventLabel: 'pdf',
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  methods: {
    uploadProgress(percent) {
      const dom = this.$refs['uploadProgress']
      const inners = dom.getElementsByClassName('inner')
      for (let i = 0; i < inners.length; i++) {
        inners[i].innerHTML = percent
      }
    },
    updateMetaballs() {
      this.$nuxt.$emit('clear-metaballs')
      this.$nextTick(() =>
        this.$nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
    uploadFile() {
      return new Promise(resolve => {
        let data = new FormData()

        data.append('file', this.selectedFile)
        this.uploadProgress('0%')
        axios
          .post('https://us-central1-outra-coisa-co-preview.cloudfunctions.net/uploads', data, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.uploadProgress('100%')
            if (response.status == 200) {
              const { path, filename } = response.data
              resolve(path + filename)
            }
            resolve('')
          })
          .catch(error => {
            console.log(error)
            this.uploadProgress('Error')
            alert(this.$t('contato.falha_ao_fazer_upload_do_arquivo_de_audio'))
            reject(this.$t('contato.falha_ao_fazer_upload_do_arquivo_de_audio'))
          })
      })
    },
    async submit() {
      const url = await this.uploadFile()
      if (url) {
        this.form.curriculo = url
        this.$emit('goToStep', this.nextStep)
      }
    },
    retry() {
      this.selectedFile = null
      this.updateMetaballs()
    },
    selectFile() {
      let input = document.createElement('input')
      input.type = 'file'
      input.accept = 'application/pdf'
      input.onchange = _ => {
        // you can use this method to get file and perform respective operations
        let files = Array.from(input.files)
        if (files[0]) {
          if (files[0].type !== 'application/pdf')
            return alert(this.$t('contato.file_must_be_a_pdf'))
          if (files[0].size / (1024 * 1024) > 5)
            return alert(this.$t('contato.file_must_be_less_than_5mb'))
        }

        this.selectedFile = files[0]
        console.log(files[0])
        this.updateMetaballs()
      }
      input.click()
    },
    goBack() {
      this.$emit('goToStep', this.backStep)
    },
    createMetaballs() {
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
  },
}
