const isEn = origin.indexOf('otherdigital') != -1;

export const state = () => ({
  all: null,
  current: null,
  locale_of_metodologia: isEn ? 'en' : 'pt-BR',
})

export const getters = {
  all(state) {
    return state.all
  },
  current(state) {
    return state.current
  },
}

export const mutations = {
  all(state, all) {
    state.all = all
  },
  current(state, current) {
    if (!current) {
      state.current = false
    } else {
      state.current = { ...current }
    }
  },
}

export const actions = {
  async fetchAll({ commit }, $content) {
    const metodologia = await $content('metodologia').fetch()
    commit('all', metodologia)
  },
  async retrieveCurrentVersion({ commit, state }) {
    console.log(state)
    commit(
      'current',
      state.all.find((p) => p.isPublished && state.locale_of_metodologia == p.locale)
    )
  },
}
