
export default {
    methods: {
        MicVisualizer_Ready(canvas) {
            canvas.width = canvas.offsetWidth * 2;
            canvas.height = canvas.offsetHeight * 2;
        },
        MicVisualizer_Draw(canvas, accRadiusList, MINOR_RING = 250, MAJOR_RING = 787) {
            const DELTA_RING = MAJOR_RING - MINOR_RING;
            const WIDTH = canvas.width;
            const HEIGHT = canvas.height;
            const CENTER_X = WIDTH / 2;
            const CENTER_Y = HEIGHT / 2;
            const _2PI = 2 * Math.PI;

            const ctx = canvas.getContext("2d");

            ctx.clearRect(0, 0, WIDTH, HEIGHT);
            ctx.fillStyle = "#000000";
            const len = accRadiusList.length
            for (let i = 0; i < len; i++) {
                ctx.lineWidth = 0.6 - ((i / len) / 2.0)
                ctx.beginPath();
                const factor_0 = (i / len);
                factor_0 *= factor_0;//ease-in

                const factor_1 = ((i + 1) / len);
                factor_1 *= factor_1;//ease-in

                const acc = accRadiusList[i] * (factor_1 - factor_0);

                ctx.arc(CENTER_X, CENTER_Y, MINOR_RING + DELTA_RING * (factor_0 + acc), 0, _2PI);
                ctx.stroke();
            }
        }
    }
}