import * as THREE from 'three'
import MetaBallIsle from './MetaBallIsle'

export default class MetaBallScene {
  constructor({ tintColor, onReady }) {
    this.onReady = onReady
    this.scene = new THREE.Scene()
    this.canvasWidth = document.documentElement.clientWidth
    this.canvasHeight = document.documentElement.clientWidth
    this.mouse = { x: 0, y: 0, position: new THREE.Vector3(0, 0, 40) }
    this.isles = []
    this.defaultTintColor = new THREE.Color(0x34E2CD)
    this.tintColor = new THREE.Color(tintColor || this.defaultTintColor)
    this.isleColor = 0xffffff

    // Settings for limit frame rate and avoid dropped frames.
    this.fpsInterval = 1000 / 60
    this.lastRenderAt = window.performance.now()
    this.lastResizeAt = window.performance.now()

    this.renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector('#metaball-canvas'),
      antialias: true,
      alpha: true,
    })

    this.renderer.domElement.style.position = 'fixed'

    this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.localClippingEnabled = true

    /** @type {DOMRect} */
    this.canvasClientRect = this.renderer.domElement.getBoundingClientRect()

    // camera
    this.camera = new THREE.OrthographicCamera(
      this.canvasWidth / -2,
      this.canvasWidth / 2,
      this.canvasHeight / 2,
      this.canvasHeight / -2,
      0.01,
      50
    )

    this.camera.position.setZ(50)

    // Define min distance **offset multiplier** to enable the metaball.
    // Ex.: `(radius1 + radius2) * metaballDistanceFactor`.
    this.metaballDistanceFactor = 1.4

    this.cursor = this.createCursor(12)
    this.scene.add(this.cursor.mesh)

    /**
     * If checkShaderErrors is true, defines whether material shader programs are checked for errors
     * during compilation and linkage process. It may be useful to disable this check in production
     * for performance gain. It is strongly recommended to keep these checks enabled during development.
     * If the shader does not compile and link - it will not work and associated material will not render.
     * Default is true.
     */
    this.renderer.debug.checkShaderErrors = process.env.nodeEnv === 'development'

    this.renderer.compile(this.scene, this.camera)
    this.renderer.render(this.scene, this.camera)

    document.addEventListener('mousemove', this.onMouseMove, {passive: true})
    document.addEventListener('mouseenter', this.onMouseEnter, {passive: true})
    document.addEventListener('mouseleave', this.onMouseLeave, {passive: true})

    this.onReady(this)
  }

  render = (now) => {
    this.requestAnimationFrameId = requestAnimationFrame(this.render)

    const elapsed = now - this.lastRenderAt

    if (elapsed > this.fpsInterval) {
      this.isles.forEach(isle => {
        isle.updatePosition()
        isle.checkMetaball()
      })

      this.lastRenderAt = now - (elapsed % this.fpsInterval)
      // this.renderer.domElement.style.transform = `translateY(${window.scrollY}px)`
      this.renderer.render(this.scene, this.camera)
      this.setSize()
      this.updateCursorBallPosition()
    }
  }

  implode() {
    if (this.requestAnimationFrameId) cancelAnimationFrame(this.requestAnimationFrameId)
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseenter', this.onMouseEnter)
    document.removeEventListener('mouseleave', this.onMouseLeave)
    this.renderer = null
    this.isles = []
  }

  clear() {
    this.tintColor = this.defaultTintColor
    this.isles.forEach(isle => this.scene.remove(isle.root))
    this.isles = []
    this.onLinkLeave()
  }

  onMouseMove = (event) => {
    this.mouse.x = event.pageX - this.canvasClientRect.left
    this.mouse.y = event.pageY - window.scrollY - this.canvasClientRect.top
    this.mouse.position.setX(event.pageX - this.canvasClientRect.left - this.canvasClientRect.width / 2)
    this.mouse.position.setY(-(event.pageY - (this.canvasClientRect.top + window.scrollY) - this.canvasClientRect.height / 2))
  }

  onMouseEnter = (event) => {
    this.cursor.mesh.visible = true
  }

  onMouseLeave = (event) => {
    this.cursor.mesh.visible = false
  }

  createCursor(radius) {
    const geometry = new THREE.CircleGeometry(radius, 16)
    const material = new THREE.MeshBasicMaterial({ color: this.tintColor })
    const mesh = new THREE.Mesh(geometry, material)

    return {
      mesh,
      radius,
    }
  }

  /**
   * @param {HTMLElement} el
   */
  createMetaBallIsleFromEl(el) {
    const radius = +el.attributes.radius?.value || 80
    const isleOpacity = +el.attributes['isle-opacity']?.value || 0.8
    const relativeImgEl = el.closest('[metaball-with-image]')?.querySelector('.img-wrapper img')

    const isle = new MetaBallIsle({
        radius,
        domEl: el,
        relativeImgEl,
        cursor: this.cursor,
        metaballDistanceFactor: this.metaballDistanceFactor,
        scene: this,
        onCreated: (_isle) => {
          _isle.fadeIn(isleOpacity)
          _isle.root.position.setZ(1)
          _isle.onCursorInside(this.makeCursorAlt)
          _isle.onCursorOutside(this.makeCursorNormal)
          this.scene.add(_isle.root)
          this.isles.push(_isle)
        }
      })

    return isle
  }

  /**
   * @param {HTMLElement} el
   */
  appendMetaballLink = (el) => {
    el.removeEventListener("mouseleave", this.onLinkLeave)
    el.removeEventListener("mouseenter", this.onLinkEnter)
    el.addEventListener("mouseleave", this.onLinkLeave, {passive: true})
    el.addEventListener("mouseenter", this.onLinkEnter, {passive: true})
  }

  onLinkLeave = () => {
    if(this.cursor.mesh)
      this.cursor.mesh.scale.set(1, 1, 1)
  }

  onLinkEnter = () => {
    if(this.cursor.mesh)
      this.cursor.mesh.scale.set(1.5, 1.5, 1.5)
  }

  makeCursorNormal = () => {
    this.cursor.mesh.material.color.set(this.tintColor)
  }

  makeCursorAlt = () => {
    const altColor = this.tintColor.clone()
    const altColorHSL = {}
    this.tintColor.getHSL(altColorHSL)
    altColor.setHSL(altColorHSL.h, altColorHSL.s - 0.31, altColorHSL.l - 0.13)
    this.cursor.mesh.material.color.set(altColor)
  }

  updateCursorBallPosition = () => {
    if (!this.cursor.mesh) return
    this.cursor.mesh.position.copy(this.mouse.position)
  }

  updateTintColor(color) {
    this.tintColor = new THREE.Color(color)
    this.isles.forEach(isle => isle.updateTintColor(this.tintColor))
  }

  updateIsleColor(color) {
    this.isleColor = new THREE.Color(color)
    this.isles.forEach(isle => isle.updateIsleColor(this.isleColor))
  }

  setSize() {
    const isStale = (window.performance.now() - this.lastResizeAt) > 5000

    if (
      isStale ||
      document.documentElement.clientWidth !== this.canvasWidth ||
      document.documentElement.clientHeight !== this.canvasHeight
    ) {
      this.canvasWidth = document.documentElement.clientWidth
      this.canvasHeight = document.documentElement.clientHeight
      this.renderer.setSize(this.canvasWidth, this.canvasHeight)

      this.camera.left = this.canvasWidth / -2
      this.camera.right = this.canvasWidth / 2
      this.camera.top = this.canvasHeight / 2
      this.camera.bottom = this.canvasHeight / -2
      this.camera.updateProjectionMatrix()

      this.canvasClientRect = this.renderer.domElement.getBoundingClientRect()
      this.lastResizeAt = window.performance.now()
    }
  }
}
