//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      temp: 'projects/temp',
      animation: 'projects/temp_animation',
    }),
  },
}
