export const state = () => ({
  all: null,
})

export const getters = {
  all(state) {
    return state.all
  },
}
//teste
export const mutations = {
  all(state, all) {
    state.all = all.sort((a, b) => a.order - b.order)
  },
}

export const actions = {
  async fetchAll({ commit }, $content) {
    const artigos = await $content('artigos').fetch()
    commit('all', artigos)
  },
}
