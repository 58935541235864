//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    template: { type: String, default: () => 'email' },
    hiringModel: { type: Object, required: true },
    step: Number,
    nextStep: String,
  },
  data: () => ({
    templates: {
      email: {
        label: 'contato.qual_o_seu_email',
        eventLabel: 'email',
        input: {
          type: 'email',
          model: 'email',
          placeholder: 'email@email.com',
          pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
      },
      name: {
        label: 'contato.qual_o_seu_nome',
        eventLabel: 'name',
        input: {
          type: 'name',
          model: 'name',
          placeholder: 'nome completo',
          pattern: false,
        },
      },
      telefone: {
        label: 'contato.qual_o_seu_telefone',
        model: 'telefone',
        input: {
          type: 'telefone',
          eventLabel: 'telefone',
          placeholder: '(99) 99999-9999',
          pattern: false,
        },
      },
    },
  }),
  mounted() {
    this.$nextTick(() =>
      setTimeout(
        () => this.$nuxt.$emit('create-metaballs', { tintColor: '#000000', isleColor: '#000000' }),
        250
      )
    )
    this.$ga.event({
      eventCategory: this.hiringModel.form == 'vaga' ? 'Careers form' : 'Contact form',
      eventAction: 'reached',
      eventLabel: this.template_data.eventLabel,
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  methods: {
    goBack() {},
    submit() {
      console.log(this.form)
      if (this.validInput) {
        this.$emit('goToStep', this.nextStep)
      } else alert(this.$t('contato.email_invalido'))
    },
  },
  computed: {
    validInput() {
      if (this.template_data.input.pattern) {
        return this.template_data.input.pattern.test(this.form[this.template_data.input.model])
      } else {
        return true
      }
    },
    template_data() {
      return this.templates[this.template]
    },
  },
}
