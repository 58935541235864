import orderBy from 'lodash/orderBy'


const origin = window.location.origin;
const isEn = origin.indexOf('otherdigital') != -1;

export const state = () => ({
  all: null,
  current: null,
  temp: null,
  locale_of_projects: isEn ? 'en' : 'pt-BR',
  temp_animation: 'forward',
})

export const getters = {
  all(state) {
    return state.all
  },
  temp(state) {
    return state.temp
  },
  temp_animation(state) {
    return state.temp_animation
  },
  current(state) {
    return state.current
  },
}

export const mutations = {
  temp(state, temp) {
    if (!temp) {
      state.temp = null
    } else {
      state.temp = { ...temp }
    }
  },
  temp_animation(state, temp_animation) {
    state.temp_animation = temp_animation
  },
  all(state, all) {
    all.forEach(project => {
      if (project.ranking === undefined) project.ranking = 0
    })

    const published = all.filter(project => {
      if (project.locale != state.locale_of_projects || project.isPublished === undefined || project.isPublished === false) {
        return false
      } else {
        return true
      }
    })

    state.all = orderBy(published, ['ranking', 'slug'], ['desc', 'asc'])
  },
  current(state, current) {
    if (!current) {
      state.current = false
    } else {
      state.current = { ...current }
    }
  },
}

export const actions = {
  async fetchAll({ commit }, $content) {
    const projects = await $content('projects').fetch()
    commit('all', projects)
  },
  async getBySlug({ commit, state }, slug) {
    commit(
      'current',
      state.all.find(p => p.slug === slug)
    )
  },
}
