//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      title: this.$t('meta.contato.title') + ' | ' + this.$t('app.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.contato.description'),
        },
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
        class: this.$router.currentRoute.name,
      },
    }
  },
  props: {
    hiringModel: { type: Object, default: () => ({}) },
  },
  methods: {
    closeModal() {
      this.$nuxt.$emit('hide-contact-modal')
    },
  },
}
