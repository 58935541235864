//
//
//
//
//
//

import MetaBallScene from './MetaBallScene'

export default {
  name: 'MetaBallContainer',
  inject: ['isMobile'],
  data: function() {
    return {}
  },
  props: {
    tintColor: {
      type: String,
      default: '#34E2CD',
    },
  },
  created() {
    if (module.hot) module.hot.dispose(this.onDispose)
  },
  mounted() {
    this.init()
  },
  destroyed() {
    if (module.hot) module.hot.removeDisposeHandler(this.onDispose)
  },
  methods: {
    init() {
      if (this.isMobile.value) return

      this.scene = new MetaBallScene({
        tintColor: this.tintColor.replace('0x', '#'),
        onReady: scene => scene.render(),
      })

      this.$nextTick(this.createMetaballLink)
    },
    reset() {
      this.scene?.implode()
      this.$nextTick(this.init)
    },
    clear() {
      this.scene.clear()
    },
    createMetaBallIsles() {
      this.$refs.root.querySelectorAll('[metaball]').forEach(el => {
        if (el.style.display !== 'none') {
          const metaballIsle = this.scene.createMetaBallIsleFromEl(el)
          this.setupClipEffect(metaballIsle, el)
        }
      })
      this.$nextTick(this.createMetaballLink)
    },
    createMetaballLink() {
      window.document.body.querySelectorAll('[metaball-link]').forEach(el => {
        this.scene.appendMetaballLink(el)
      })
    },
    onDispose() {
      this.scene.implode()
    },
    setupClipEffect(metaballIsle, domEl) {
      const inner = domEl.querySelector('.inner')
      const innerClone = inner.cloneNode(true)
      innerClone.classList.add('inner-clone')
      domEl.appendChild(innerClone)

      metaballIsle.onCursorInside(() => {
        innerClone.classList.add('active')
        domEl.classList.add('active')
      })

      metaballIsle.onCursorOutside(() => {
        innerClone.classList.remove('active')
        domEl.classList.remove('active')
      })
    },
    updateIsleColor(color) {
      this.scene.updateIsleColor(color.replace('0x', '#'))
    },
    updateTintColor(color) {
      this.scene.updateTintColor(color.replace('0x', '#'))
    },
  },
}
