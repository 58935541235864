import Vue from 'vue'
import { Notifier } from '@airbrake/browser';

var airbrake = new Notifier({
  environment: 'production',
  projectId: 382433,
  projectKey: 'd8f4c50f5c14b708880dd9e4325acc65'
});

Vue.config.errorHandler = function (err, vm, info) {
  console.error(err);
  airbrake.notify({
    error: err,
    params: {info: info}
  });
}