//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default {
  provide() {
    return {
      metaballContainer: this.metaballContainer,
      isMobile: this.isMobile,
      verticalBreakpoint: 750,
      debugMarkers: this.debugMarkers,
    }
  },
  async middleware({ store, $content }) {
    if (!store.state.projects?.all) await store.dispatch('projects/fetchAll', $content)
    if (!store.state.vagas?.all) await store.dispatch('vagas/fetchAll', $content)
    if (!store.state.metodologia?.all) await store.dispatch('metodologia/fetchAll', $content)
  },
  data() {
    return {
      isOpen: false,
      contactModal: false,
      animatedModal: false,
      showContent: true,
      overlayEverything: false,
      debugMarkers: {
        value: false,
      },
      isMobile: {
        value: undefined,
      },
      metaballContainer: {
        value: undefined,
      },
      desktopMediaQuery: window.matchMedia('(min-width: 1320px)'),
      isLoadingCoverEnabled: true,
      enableDebugControls: !!window.location.search.match('debug=true'),
    }
  },
  watch: {
    'isMobile.value': {
      handler: function(newValue, oldValue) {
        if (oldValue === undefined) return
        if (newValue !== oldValue) window.location.reload()
      },
      deep: true,
    },
    $route(to, from) {
      this.hideContactModal()
    },
  },
  methods: {
    close() {
      if (this.isOpen) {
        this.$refs['navmenu'].$el.classList.add('closing')
        document.body.style.height = 'inherit'
        document.body.style.overflowY = 'auto'
        setTimeout(() => {
          this.isOpen = false
          this.$forceUpdate()
        }, 250)
      }
    },
    open() {
      this.isOpen = true
      document.body.style.height = '100%'
      document.body.style.overflowY = 'hidden'
    },
    toggle() {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    showContactModal(hiringModel) {
      this.contactModal = hiringModel
      this.animatedModal = 'normal-animation'
      this.overlayEverything = true
      this.$nextTick(() => {
        this.$nuxt.$emit('clear-metaballs')
      })
      setTimeout(() => {
        this.showContent = false
        this.overlayEverything = false
      }, 100)
    },
    hideContactModal() {
      this.showContent = true
      if (this.contactModal) {
        this.contactModal = false
        this.animatedModal = 'reverse-animation'
        this.overlayEverything = true
        setTimeout(() => {
          this.overlayEverything = false
          this.animatedModal = false
        }, 250)
      } else {
        this.contactModal = false
        this.animatedModal = false
      }
    },
    checkDesktop(ev) {
      this.isMobile.value = !(ev || this.desktopMediaQuery).matches
    },
    onShapesInit() {
      this.isLoadingCoverEnabled = false
    },
    onProjectTransition(options = {}) {
      if (options.toPrevious) {
        this.$refs.projectTransition.toPrevious()
      } else {
        this.$refs.projectTransition.toNext()
      }
    },
    createMetaballs(options = {}) {
      if (options.tintColor) this.metaballContainer.value?.updateTintColor(options.tintColor)
      if (options.isleColor) this.metaballContainer.value?.updateIsleColor(options.isleColor)
      else this.metaballContainer.value?.updateIsleColor('#FFFFFF')
      this.metaballContainer.value?.createMetaBallIsles()
    },
    clearMetaballs() {
      this.metaballContainer.value?.clear()
    },
    setupNetlifyIdentify() {
      window.netlifyIdentity.on('init', user => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/'
          })
        }
      })
    },
  },
  created() {
    this.desktopMediaQuery.addListener(this.checkDesktop)
    this.checkDesktop()
  },
  mounted() {
    this.metaballContainer.value = this.$refs.metaballContainer

    this.$nuxt.$on('shapes-init', this.onShapesInit)
    this.$nuxt.$on('with-no-shapes-init', this.onShapesInit)
    this.$nuxt.$on('project-transition', this.onProjectTransition)

    this.$nuxt.$on('show-contact-modal', this.showContactModal)
    this.$nuxt.$on('hide-contact-modal', this.hideContactModal)
    this.$nuxt.$on('close-nav', this.close.bind(this))
    this.$nuxt.$on('toggle-nav', this.toggle.bind(this))
    if (!this.isMobile.value) {
      this.$nuxt.$on('create-metaballs', this.createMetaballs)
      this.$nuxt.$on('clear-metaballs', this.clearMetaballs)
    }

    document.querySelector('html').classList.remove('loading')
    if (window.netlifyIdentity) this.setupNetlifyIdentify()
  },
  destroyed() {
    this.desktopMediaQuery.removeListener(this.checkDesktop)
  },
}
