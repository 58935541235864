//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    template: { type: String, default: () => 'contact' },
    hiringModel: { type: Object, required: true },
    step: Number,
    nextStep: String,
    backStep: String,
  },
  data: () => ({
    templates: {
      contact: {
        label: 'contato.me_passa_seus_contatos',
        eventLabel: 'contact',
        input: [
          {
            type: 'email',
            label: 'email',
            model: 'email',
            placeholder: 'email@email.com',
            pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          },
          {
            label: 'telefone',
            type: 'telefone',
            model: 'telefone',
            eventLabel: 'telefone',
            placeholder: '(99) 99999-9999',
            pattern: false,
          },
        ],
      },
      links: {
        label: 'contato.adicione_seus_links',
        eventLabel: 'links',
        input: [
          {
            type: 'link',
            label: 'portfolio',
            model: 'portfolio',
            placeholder: 'escreva aqui',
            pattern: false,
          },
          {
            label: 'linkedin',
            type: 'link',
            model: 'linkedin',
            placeholder: 'escreva aqui',
            pattern: false,
          },
        ],
      },
    },
  }),
  mounted() {
    this.createMetaballs()
    this.$ga.event({
      eventCategory: 'Careers form',
      eventAction: 'reached',
      eventLabel: this.template_data.eventLabel,
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  methods: {
    goBack() {
      this.$emit('goToStep', this.backStep)
    },
    submit() {
      console.log(this.form)
      if (this.validInput) {
        this.$emit('goToStep', this.nextStep)
      } else alert(this.$t('contato.email_invalido'))
    },
    createMetaballs() {
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
  },
  computed: {
    validInput() {
      if (this.template_data.input[0].pattern) {
        if (!this.template_data.input[0].pattern.test(this.form[this.template_data.input[0].model]))
          return false
      }
      if (this.template_data.input[1].pattern) {
        if (!this.template_data.input[1].pattern.test(this.form[this.template_data.input[1].model]))
          return false
      }
      return true
    },
    template_data() {
      return this.templates[this.template]
    },
  },
}
