//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import audioVisualizer from '@/mixins/audio-visualizer.js'
import PauseIcon from '@/components/contact/PauseIcon'
import PlayIcon from '@/components/contact/PlayIcon'
import axios from 'axios'

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
  },
  mixins: [audioVisualizer],
  components: {
    PauseIcon,
    PlayIcon,
  },
  data: () => ({
    RAF: false,
    label: false,
    running: false,
    audio: false,
    startPlayDate: false,
    lockedSubmit: false,
  }),
  mounted() {
    this.updateMetaballs()
    this.$nextTick(() => {
      this.AudioVisualizer_Ready(this.$refs['audio-vis'])
      this.draw(0)
    })
    this.updateDuration()
    this.createAudio()
    this.$ga.event({
      eventCategory: 'Contact form',
      eventAction: 'reached',
      eventLabel: 'review-the-record',
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
    cancelAnimationFrame(this.RAF)
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    success() {
      this.$emit('goToStep', 'success')
    },
    fail() {
      alert(this.$t('contato.falha_ao_enviar_formulário_tente_novamente_mais_tarde'))
    },
    sendForm(url) {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
      const form = {
        email: this.form.email,
        name: this.form.email,
        phone: 'N/A',
        company: 'N/A',
        message: 'Copie e cole o url no navegador para ouvir o áudio: ' + url,
      }
      axios
        .post(
          '/contato-ok',
          this.encode({
            'form-name': 'contact',
            ...form,
          }),
          axiosConfig
        )
        .then(this.success.bind(this))
        .catch(this.fail.bind(this))
    },
    uploadProgress(percent) {
      const dom = this.$refs['uploadProgress']
      const inners = dom.getElementsByClassName('inner')
      for (let i = 0; i < inners.length; i++) {
        inners[i].innerHTML = percent
      }
    },
    uploadFile() {
      return new Promise((resolve, reject) => {
        let data = new FormData()

        data.append('file', this.form.audioBlob)
        this.uploadProgress('0%')
        axios
          .post('https://us-central1-outra-coisa-co-preview.cloudfunctions.net/uploads', data, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.uploadProgress('100%')
            if (response.status == 200) {
              const { path, filename } = response.data
              resolve(path + filename)
            }
            resolve('')
          })
          .catch(error => {
            console.log(error)
            this.uploadProgress('Error')
            alert(this.$t('contato.falha_ao_fazer_upload_do_arquivo_de_audio'))
            reject(this.$t('contato.falha_ao_fazer_upload_do_arquivo_de_audio'))
          })
      })
    },
    requestSubmit() {
      if (!this.lockedSubmit) {
        this.lockedSubmit = true
        this.uploadFile()
          .then(url => {
            this.sendForm(url)
            this.lockedSubmit = false
          })
          .catch(() => {
            this.lockedSubmit = false
          })
      }
    },
    submit() {
      this.requestSubmit()
    },
    retry() {
      this.$ga.event({
        eventCategory: 'Contact form',
        eventAction: 'return from',
        eventLabel: 'review-the-record',
      })
      this.$emit('goToStep', 'voice-record')
    },
    toggle() {
      if (this.running) {
        this.pause()
      } else {
        this.play()
      }
    },
    play() {
      this.running = true
      this.audio.play()
      this.tickAnimation()
      this.startPlayDate = Date.now()
    },
    pause() {
      this.running = false
      cancelAnimationFrame(this.RAF)
      this.audio.pause()
    },
    end() {
      this.running = false
      this.audio.currentTime = 0
      this.draw()
      cancelAnimationFrame(this.RAF)
    },
    draw(d) {
      const time = d !== undefined ? d : Math.min(this.audio.currentTime / this.duration, 1)
      const RADIUS = this.isMobile ? 297 : 424
      const LINE_WIDTH = this.isMobile ? 20 : 20

      this.AudioVisualizer_Draw(this.$refs['audio-vis'], time, RADIUS, LINE_WIDTH)
    },
    tickAnimation() {
      this.cancelAnimation()
      this.draw()
      this.RAF = requestAnimationFrame(this.tickAnimation)
    },
    cancelAnimation() {
      cancelAnimationFrame(this.RAF)
    },
    error() {
      this.label = 'error'
    },
    createAudio() {
      this.audio = new Audio(URL.createObjectURL(this.form.audioBlob))
      this.audio.addEventListener('ended', this.end)
      this.audio.addEventListener('error', this.error)
    },
    updateDuration(duration) {
      if (!Number.isFinite(duration) || Number.isNaN(duration)) {
        duration = this.form.audioDuration
      }
      this.duration = Math.floor(duration)
      const delta_seconds = Math.floor(duration)
      const delta_minutes = Math.floor(delta_seconds / 60)
      this.label = delta_minutes + ':' + ('' + (delta_seconds % 60)).padStart(2, '0')
    },
    updateMetaballs() {
      this.$nuxt.$emit('clear-metaballs')
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
  },
  computed: {},
}
