//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  data() {
    return {
      lastScroll: false,
      stickyTranslate: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true })
    this.onScroll()
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    toggle() {
      this.$nuxt.$emit('toggle-nav')
    },
    updateTranslate(){
      this.$refs['main-nav'].style.transform = `translateY(${this.stickyTranslate}px)`
    },
    onScroll() {
      const mainNav = this.$refs['main-nav']
      if (!mainNav) return
      let target = window
      if (this.lastScroll == false) {
        this.lastScroll = target.scrollY
      } else {
        this.stickyTranslate -= target.scrollY - this.lastScroll
        this.stickyTranslate = this.clamp(this.stickyTranslate, -1 * mainNav.offsetHeight, 0)
        this.lastScroll = target.scrollY
      }
      if (target.scrollY <= 20) {
        mainNav.classList.add('onTop')
      } else {
        mainNav.classList.remove('onTop')
      }
      this.updateTranslate()
    },
    clamp(a, min, max) {
      return Math.min(Math.max(a, min), max)
    },
    onUnpin() {
      if (this.isOpen) {
        this.toggle()
      }
    },
  },
}
