//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import micVisualizer from '@/mixins/mic-visualizer.js'
import MicIcon from '@/components/contact/MicIcon'

export default {
  props: {
    form: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
  },
  components: {
    MicIcon,
  },
  mixins: [micVisualizer],
  data: () => ({
    RAF: false,
    recording: false,
    time_recorded: false,
    audioChunks: false,
    mediaRecorder: false,
    start_date: false,
  }),
  mounted() {
    this.updateMetaballs()
    this.$ga.event({
      eventCategory: 'Contact form',
      eventAction: 'reached',
      eventLabel: 'voice-form',
    })
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
    this.stopRecording()
  },
  methods: {
    updateMetaballs() {
      this.$nuxt.$emit('clear-metaballs')
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#5650A1', isleColor: '#241f2f' })
      )
    },
    excluir() {
      this.stopRecording()
    },
    pronto() {
      this.form.audioDuration = (Date.now() - this.start_date) / 1000
      const audioBlob = new Blob(this.audioChunks, {
        type: 'audio/mp3',
      })
      this.form.audioBlob = audioBlob
      this.stopRecording()
      this.$emit('goToStep', 'review-record')
    },
    goBack() {
      this.$ga.event({
        eventCategory: 'Contact form',
        eventAction: 'return from',
        eventLabel: 'voice-form',
      })
      this.stopRecording()
      this.$emit('goToStep', 'method')
    },
    stopRecording() {
      if (this.recording) {
        this.mediaRecorder.removeEventListener('dataavailable', this.appendAudioChunk)
        this.mediaRecorder.stop()
        this.mediaRecorder = false
        this.audioChunks = false
        this.time_recorded = false
        this.recording = false
        cancelAnimationFrame(this.RAF)
        this.updateMetaballs()
      }
    },
    startRecording() {
      if (this.recording) {
        return
      }
      navigator.mediaDevices.getUserMedia({ audio: true }).then(this.hear)
    },
    updateTime(deltaTime) {
      const delta_seconds = Math.floor(deltaTime / 1000)
      const delta_minutes = Math.floor(delta_seconds / 60)
      this.time_recorded = delta_minutes + ':' + ('' + (delta_seconds % 60)).padStart(2, '0')
    },
    hear(source) {
      this.updateTime(0)
      this.recording = true

      //exibi a visualização
      var context = new AudioContext()
      var src = context.createMediaStreamSource(source)

      var analyser = context.createAnalyser()
      var listen = context.createGain()

      src.connect(listen)
      listen.connect(analyser)
      analyser.fftSize = 256

      var bufferLength = analyser.frequencyBinCount
      let dataArray = new Uint8Array(bufferLength)

      this.start_date = Date.now()

      const totalOfRings = 15
      const rings = []
      for (let i = 0; i < totalOfRings; i++) {
        rings[i] = 0
      }
      const canvas = this.$refs['canvas-vis-mic']
      const chunkFrequencySize = (bufferLength - 100) / totalOfRings //50 is sensability [0 -> 200]
      this.$nextTick(() => this.MicVisualizer_Ready(canvas))
      const renderFrame = () => {
        analyser.smoothingTimeConstant = 0.5

        listen.gain.setValueAtTime(1, context.currentTime)

        analyser.getByteFrequencyData(dataArray)

        // analyser.getByteTimeDomainData(dataArray)

        for (let i = 0; i < totalOfRings; i++) {
          const from = Math.floor(i * chunkFrequencySize)
          const to = Math.ceil((i + 1) * chunkFrequencySize)
          let sum = 0
          let count = 0
          for (let j = from; j < to; j++) {
            sum += dataArray[j] / 200
            count++
          }
          rings[i] = sum / count
        }
        this.updateTime(Date.now() - this.start_date)
        if (this.isMobile) {
          this.MicVisualizer_Draw(canvas, rings, 160, 456)
        } else {
          this.MicVisualizer_Draw(canvas, rings, 250, 787)
        }
        this.RAF = requestAnimationFrame(renderFrame)
      }
      this.startMediaRecording(source)

      renderFrame()
      this.updateMetaballs()
    },
    appendAudioChunk(event) {
      this.audioChunks.push(event.data)
    },
    startMediaRecording(source) {
      //grava o audio
      this.mediaRecorder = new MediaRecorder(source)
      this.mediaRecorder.start(100)

      this.audioChunks = []

      this.mediaRecorder.addEventListener('dataavailable', this.appendAudioChunk)
    },
  },
  computed: {},
}
