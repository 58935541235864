
const _2PI = 2 * Math.PI;

const toRadians = function (degree) {
    return (degree / 360) * _2PI
}

const _start_degree = toRadians(-80);
const _delta_degree = toRadians(340);
const _final_degree = _start_degree + _delta_degree;

export default {
    methods: {
        AudioVisualizer_Ready(canvas) {
            canvas.width = canvas.offsetWidth * 2;
            canvas.height = canvas.offsetHeight * 2;
        },
        AudioVisualizer_Draw(canvas, currentTime, RADIUS = 424, LINE_WIDTH = 20) {
            const WIDTH = canvas.width;
            const HEIGHT = canvas.height;
            const CENTER_X = WIDTH / 2;
            const CENTER_Y = HEIGHT / 2;

            const currentDegree = _start_degree + _delta_degree * currentTime;

            const ctx = canvas.getContext("2d");

            ctx.clearRect(0, 0, WIDTH, HEIGHT);
            ctx.strokeStyle = "#42a8bc";
            ctx.lineWidth = LINE_WIDTH;
            ctx.beginPath();
            ctx.arc(CENTER_X, CENTER_Y, RADIUS, _start_degree, _final_degree);
            ctx.stroke();

            ctx.strokeStyle = "#5261a7";
            ctx.lineWidth = LINE_WIDTH;
            ctx.beginPath();
            ctx.arc(CENTER_X, CENTER_Y, RADIUS, _start_degree, currentDegree);
            ctx.stroke();

            ctx.fillStyle = "#5261a7";
            ctx.beginPath();
            const cx = CENTER_X + (RADIUS) * Math.cos(currentDegree);
            const cy = CENTER_Y + (RADIUS) * Math.sin(currentDegree);
            ctx.arc(cx, cy, LINE_WIDTH / 2.0, 0, 2 * Math.PI);
            ctx.fill();
        }
    }
}