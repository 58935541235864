//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'

export default {
  props: {
    hiringModel: { type: Object, required: true },
    form: { type: Object, required: true },
    step: Number,
    nextStep: String,
    backStep: String,
  },
  data: () => ({
    metaballsActive: false,
    isMobile: { type: Boolean, required: true },
    hiringModel: { type: Object, required: true },
  }),
  mounted() {
    this.$refs.text.focus()
    this.$ga.event({
      eventCategory: 'Careers form',
      eventAction: 'reached',
      eventLabel: 'more-details',
    })
  },
  methods: {
    goBack() {
      this.$emit('goToStep', this.backStep)
    },
    encode(data) {
      return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    success() {
      this.$emit('goToStep', 'success')
    },
    fail() {
      alert(this.$t('contato.falha_ao_enviar_formulário_tente_novamente_mais_tarde'))
    },
    sendForm() {
      axios
        .post(
          'https://us-central1-outra-coisa-co-preview.cloudfunctions.net/email_managment/novo-candidato',
          {
            nome: this.form.name,
            email: this.form.email,
            telefone: this.form.telefone,
            curriculo: this.form.curriculo,
            portfolio: this.form.portfolio,
            linkedin: this.form.linkedin,
            mensagem: this.form.text,
            vaga: this.hiringModel.vaga,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: '69cdcc7eb4b306e3370495a66839e3c3207ddc0c3424d35db06777877642035e',
            },
          }
        )
        .then(response => {
          if (response.data.error) return console.error(response.data.error)
          this.$emit('goToStep', 'success')
          console.log(response)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    submit() {
      this.sendForm()
    },
    createMetaballs() {
      this.$nextTick(() =>
        $nuxt.$emit('create-metaballs', { tintColor: '#000000', isleColor: '#000000' })
      )
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        if (this.form.text.length >= 0 && !this.metaballsActive) {
          this.metaballsActive = true
          this.createMetaballs()
        } else if (this.form.text.length == 0 && this.metaballsActive) {
          this.metaballsActive = false
          this.createMetaballs()
        }
      },
    },
  },
  destroyed() {
    $nuxt.$emit('clear-metaballs')
  },
  computed: {
    validText() {
      return this.form.text.length !== 0
    },
  },
}
