export default {
  app: {
    title: 'Outra Coisa',
    logo: '/logo.svg',
  },
  message: {
    'conheca-mais': 'Conheça mais sobre o projeto ',
  },
  meta: require('./meta.json'),
  home: require('./home.json'),
  trabalhos: require('./trabalhos.json'),
  contato: require('./contato.json'),
  misc: require('./misc.json'),
  metodologia: require('./metodologia.json'),
}
