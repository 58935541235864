export const state = () => ({
  all: null,
  current: null,
})

export const getters = {
  all(state) {
    return state.all
  },
  current(state) {
    return state.current
  },
}

export const mutations = {
  all(state, all) {
    state.all = all
  },
  current(state, current) {
    if (!current) {
      state.current = false
    } else {
      state.current = { ...current }
    }
  },
}

export const actions = {
  async fetchAll({ commit }, $content) {
    const vagas = await $content('vagas').fetch()
    commit('all', vagas)
  },
  async getBySlug({ commit, state }, slug) {
    console.log(state)
    commit(
      'current',
      state.all.find(p => p.slug === slug)
    )
  },
}
