import gsap from 'gsap'
import * as THREE from 'three'
import MetaBallService from './MetaBallService'

export default class MetaBallIsle {
  constructor({ radius, cursor, metaballDistanceFactor, domEl, scene, relativeImgEl, onCreated }) {
    this.originalRadius = radius
    this.radius = radius
    this.cursor = cursor
    this.metaballDistanceFactor = metaballDistanceFactor
    this.domEl = domEl
    this.relativeImgEl = relativeImgEl
    this.coordsFromRelativeImg = this.getCoordsFromRelativeImg()
    this.scene = scene
    this.onCreated = onCreated
    this.root = new THREE.Group()
    this.clippingPlane = new THREE.Plane()
    this.metaballService = new MetaBallService()
    this.callbacks = []
    this.ringOpacity = +domEl.attributes['isle-opacity']?.value || 0.8
    this.tintColor = this.scene.tintColor || this.scene.defaultTintColor
    this.isleColor = this.scene.isleColor || 0xffffff
    this.drawFillConstant = { constant: null }

    this.drawFillTween = gsap.fromTo(
      this.drawFillConstant,
      {constant: -this.radius},
      {constant: this.radius, duration: this.radius * 2, ease: 'power3.inOut'}
    )

    this.scaleFromRelativeImg(() => this.setup())
  }

  setup() {
    this.domEl.style.width = `${this.radius * 2}px`
    this.domEl.style.height = `${this.radius * 2}px`

    this.innerCircle = this.createInnerCircle(this.radius)
    this.ringCircle = this.createRingCircle(this.radius)
    this.metaball = this.createMetaball()

    this.metaball.renderOrder = 5
    this.ringCircle.renderOrder = 4

    this.root.add(this.innerCircle)
    this.root.add(this.ringCircle)
    this.root.add(this.metaball)

    this.fadeIn(this.ringOpacity)
    this.root.position.setZ(1)

    this.updatePosition()
    this.resetFill()
    this.onCreated(this)
  }

  createInnerCircle(radius) {
    const mesh =  new THREE.Mesh(
      new THREE.CircleGeometry(radius, this.segmentsByRadius(this.radius)),
      new THREE.MeshBasicMaterial({
        color: this.tintColor,
        clippingPlanes: [this.clippingPlane],
      })
    )

    /**
     * Check whether image is given and load it.
     */
    {
      const imgSrc = this.domEl.attributes['img-src']?.value

      if (imgSrc) {
        mesh.material.color.set(0xffffff)
        // this.innerCircle.material.transparent = true
        this.loadImage(imgSrc)
      }
    }

    return mesh
  }

  createRingCircle(radius) {
    return new THREE.Mesh(
      new THREE.RingGeometry(radius - 1, radius, this.segmentsByRadius(this.radius)),
      new THREE.MeshBasicMaterial({
        color: this.isleColor,
        transparent: true,
        opacity: 0,
      })
    )
  }

  createMetaball() {
    return new THREE.Mesh(
      new THREE.BufferGeometry(),
      new THREE.MeshBasicMaterial({ color: this.tintColor, transparent: true })
    )
  }

  fadeIn(opacity) {
    gsap.to(this.ringCircle.material, { opacity, duration: 0.7 })
  }

  drawFill() {
    const angle = Math.atan2(
      this.cursor.mesh.position.y - this.root.position.y,
      this.cursor.mesh.position.x - this.root.position.x
    )
    const normal = new THREE.Vector3(Math.cos(angle), Math.sin(angle), 0)

    const constant = gsap.utils.mapRange(
      (this.radius + this.cursor.radius) * this.metaballDistanceFactor,
      this.radius,
      -this.radius,
      this.radius,
      this.root.position.distanceTo(this.cursor.mesh.position)
    )

    // TODO: 'Easing' animations seems not to be working great with large metaballs.
    if (this.radius > 198) {
      this.clippingPlane.set(normal, constant)
    } else {
      this.drawFillTween.seek(constant)
      this.clippingPlane.set(normal, this.drawFillConstant.constant)
    }

    this.clippingPlane.translate(this.root.position)
  }

  resetFill() {
    return this.clippingPlane.set(new THREE.Vector3(1, 0, 0), -9999)
  }

  isCursorInside() {
    return (
      this.root.position.distanceTo(this.cursor.mesh.position) < this.radius + this.cursor.radius
    )
  }

  checkMetaball() {
    const result = {}

    const metaballPath = this.metaballService.run(
      this.cursor.radius,
      this.radius,
      [this.cursor.mesh.position.x, this.cursor.mesh.position.y],
      [this.root.position.x, this.root.position.y],
      this.metaballDistanceFactor
    )

    const isCursorInside = this.isCursorInside()

    if (metaballPath) {
      const points = metaballPath.getPoints(50)
      const shape = new THREE.Shape(points)
      const geometry = new THREE.ShapeGeometry(shape)

      this.metaball.geometry = geometry
      this.metaball.visible = true
      this.metaball.position.set(this.root.position.x * -1, this.root.position.y * -1, 0)

      this.drawFill()

      result.hasMetaballPath = true
    } else {
      result.hasMetaballPath = false
      this.metaball.visible = false

      if (isCursorInside) {
        this.drawFill()
      } else {
        this.resetFill()
      }
    }

    if (isCursorInside) {
      if (this.currentCursorSide !== 'inside') {
        this.currentCursorSide = 'inside'
        this.runCallbacks('onCursorInside')
      }
    } else {
      if (this.currentCursorSide !== 'outside') {
        this.currentCursorSide = 'outside'
        this.runCallbacks('onCursorOutside')
      }
    }

    return result
  }

  scaleFromRelativeImg(callback) {
    if (!this.relativeImgEl) {
      callback()
      return
    }

    if (!this.relativeImgEl.complete) {
      this.relativeImgEl.onLoad = this.scaleFromRelativeImg.bind(this)
      return
    }

    if (this.relativeImgEl.naturalWidth === this.relativeImgEl.clientWidth) {
      callback()
      return
    }

    const scale = this.relativeImgEl.clientWidth / this.relativeImgEl.naturalWidth
    this.radius = this.originalRadius * scale
    this.coordsFromRelativeImg.x = this.coordsFromRelativeImg.originalX * scale
    this.coordsFromRelativeImg.y = this.coordsFromRelativeImg.originalY * scale

    callback()
  }

  /**
   * Update position based on reference DOM element.
   */
  updatePosition() {
    if (this.coordsFromRelativeImg) {
      /**
       * Commented in case need to calculate the offset between the main image wrapper and the main
       * image itself.
       */
      // const relativeImgElBounding = this.relativeImgEl.getBoundingClientRect()
      // const relativeImgElParentBounding = this.relativeImgEl.parentElement.getBoundingClientRect()
      // const offsetBottom = (relativeImgElParentBounding.y + relativeImgElParentBounding.height)
      //                     - (relativeImgElBounding.y + relativeImgElBounding.height)

      let posLeft = `${this.coordsFromRelativeImg.x - this.radius + this.relativeImgEl.offsetLeft}px`
      let posTop = `${this.coordsFromRelativeImg.y - this.radius}px`

      this.domEl.style.left = posLeft
      this.domEl.style.top = posTop
    }

    const bounding = this.domEl.getBoundingClientRect()
    const cc = this.scene.canvasClientRect
    this.root.position.set(
      (bounding.x + bounding.width / 2 - cc.x - cc.width / 2),
      (bounding.y + bounding.height / 2 - cc.y - cc.height / 2) * (-1),
      0
    )
  }

  updateIsleColor(color) {
    this.isleColor = color
  }

  updateTintColor(color) {
    this.tintColor = color
  }

  loadImage(url) {
    new THREE.TextureLoader().load(url, texture => {
      this.innerCircle.material.map = texture
      this.innerCircle.material.needsUpdate = true
    })
  }

  runCallbacks(callbackName) {
    this.callbacks[callbackName].forEach(cb => cb())
  }

  onCursorInside(callback) {
    this.callbacks['onCursorInside'] = this.callbacks['onCursorInside'] || []
    this.callbacks['onCursorInside'].push(callback)
  }

  onCursorOutside(callback) {
    this.callbacks['onCursorOutside'] = this.callbacks['onCursorOutside'] || []
    this.callbacks['onCursorOutside'].push(callback)
  }

  getCoordsFromRelativeImg() {
    const coords =  this.domEl.attributes['coords']?.value?.split(',')?.map(c => +c)

    if (coords) return {
      x: coords[0],
      y: coords[1],
      originalX: coords[0],
      originalY: coords[1]
    }
  }

  segmentsByRadius(radius) {
    return Math.round(gsap.utils.mapRange(32, 74, 50, 300, radius))
  }
}
