//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    //modos disponíveis: nav, contato
    mode: { type: String, required: false, default: () => 'contato' },
  },
  // prettier-ignore
  data: () => ({
    links: [
      { title: 'vamos@outracoisa.co' , href: 'mailto:vamos@outracoisa.co'                               , ficon: '' , class: 'show-only-desktop'                   },
      { title: '+55 21 99174 7497'   , href: 'https://wa.me/5521991747497'                              , ficon: 'fab fa-whatsapp' , class: 'show-only-desktop'                   },
    ],
    socials: [
      { title: "+55 21 99174 7497"   , href: 'https://wa.me/5521991747497'                              , ficon: ''  , class: 'show-only-mobile', hidden_on: 'nav' },
      { title: "vamos@outracoisa.co" , href: 'mailto:vamos@outracoisa.co'                               , ficon: 'far fa-envelope'  , class: 'show-only-mobile', hidden_on: 'nav' },
      { title: "LinkedIn"            , href: 'https://www.linkedin.com/company/outra-coisa'             , ficon: 'fab fa-linkedin'  , class: ''                                   },
      { title: "Instagram"           , href: 'https://www.instagram.com/outracoisadigital/'             , ficon: 'fab fa-instagram' , class: ''                                   },
      { title: "Facebook"            , href: 'https://www.facebook.com/outracoisadigital'               , ficon: 'fab fa-facebook'  , class: 'hidden',hidden_on: 'contato'                                  },
    ],
  }),
  computed: {
    maps_link() {
      return 'https://www.google.com/maps/place/R.+Maria+Eugênia,+300+-+Humaitá,+Rio+de+Janeiro+-+RJ,+22261-080/@-22.9560191,-43.2014782,17z/data=!4m5!3m4!1s0x997fd880086def:0x686e8277ac964e10!8m2!3d-22.9555828!4d-43.2016125'
    },
    computed_socials() {
      return this.socials.filter((d) => d.hidden_on != this.mode)
    },
  },
  async fetch() {
    // this.cases = (await this.$content('cases').fetch()).filter(c => c.link.length)
  },
}
