import Vue from 'vue'
import snarkdown from 'snarkdown'

Vue.filter('striphtml', function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

Vue.filter('markdown', function (value) {
  return snarkdown(value)
});
