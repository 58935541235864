//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    totals: { type: Number, default: 5 },
    step: { type: Number },
  },
}
