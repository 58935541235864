import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3778594e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=3778594e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3778594e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/opt/build/repo/components/contact/Form.vue').default,Footer: require('/opt/build/repo/components/Footer.vue').default})
